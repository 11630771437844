<template>
    <div>
        <div class="auth-layout">
            <div class="auth-layout__container">
                <div class="auth-layout__logo">
                    <img src="@/assets/images/runshop-logo.svg" alt="Runshop" />
                </div>
                <div class="auth-layout__content">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountLayout',
    components: {},
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.auth-layout {
    display: flex;
    width: 100%;
    min-height: 100vh;
    padding: 42px;
    justify-content: center;
    align-items: center;
    background: $secondary;

    &__container {
        padding: 64px 38px;
        width: 90%;
        max-width: 600px;
        // min-height: 78vh;
        border-radius: 15px;
        background: #fff;
        box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    }

    &__content {
    }
    &__logo {
        max-width: 168px;
        max-height: 24px;
        margin-bottom: 38px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
